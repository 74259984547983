




/* .main-box::-webkit-scrollbar {
    width: 8px;
  } */
/*   
  .main-box::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    border-radius: 4px;
  }
  
  .main-box::-webkit-scrollbar-thumb:hover {
    background-color: #2196f3;
  } */

  
  ::-webkit-scrollbar {
    height: 12px !important; /* Adjust the height as needed */
    width: 15px !important; /* Adjust the height as needed */
  }