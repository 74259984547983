
.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  
    width: 100%;
    /* height: 384px; */
    height: auto;
  
    /* Theme Color/White */
    background: #FFFFFF;
    /* Inside auto layout */
  }


  
.login-welcome {
    width: 100%;
    height: 42px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 123.5%;
  
    text-align: center;
    letter-spacing: 0.25px;
  
    color: #222222;
  }


  

.login-info {
    width: 100%;
    height: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    text-align: center;
    letter-spacing: 0.4px;
  
    color: #222222;
  
  }

  
.login-button {

/* components/button-large */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 26px;
/* identical to box height, or 173% */

letter-spacing: 0.46px;
text-transform: uppercase;

/* primary/contrast */

color: #FFFFFF;


}

.login-footer-notes {
  /* typography/custom */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */

letter-spacing: 0.17px;

/* Flowkit/Charcoal */

color: #222222;
}

.login-footer-link {
  color: #222222;
  font-weight: 400;
  text-decoration: underline;
}


.login-footer-link1 {
  color: #222222;
  font-weight: 400;
}

.lock-dp {
  text-align: center;
}


.lock-screen-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  width: 368px;
  height: 300px;

  /* Theme Color/White */
  background: #FFFFFF;
  /* Inside auto layout */
}





@media (max-width: 670px) {
  .login-footer-cols{
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  
}




@media (max-width: 350px) {
  .login-welcome{
    font-size: 30px;
  }
  
}



